
        const hostname = 'notes.quantecon.org';
        const port = '3000';
        const debug = false;
        const serverPort = '8080';
        const max_num_admins = 10;
        module.exports = {
            debug: debug,
            url: 'http://' + hostname,
            urlPlusPort: 'http://' + hostname + ':' + serverPort,
            serverPort,
            max_num_admins,
            port
        };
        
